

const getBaseUrl = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/limeeasy/tha-api/api`;
        return apiUrl;
    }
    else{
        return "";
    }
}

const getTemplate = () => {
    if(window?.location?.origin){
        var base_url = window.location.origin;

        var apiUrl = `${base_url}/limeeasy/tha-api/template_limeeasy.xlsx`;
        return apiUrl;
    }
    else{
        return "";
    }
}


const appConfig = {
    pathUserTemplate: 'https://utcagm-api.eventqiu.com/public/template/utc-agm_user_template.xlsx',
    // pathUserTemplate: getTemplate(),
    pathCodeTemplate: '',
    // apiPrefix: 'https://tha-api.eventqiu.com/api',
    apiPrefix: 'https://utcagm-api.eventqiu.com',
    // apiPrefix: getBaseUrl(),
    authenticatedEntryPath: '/admin-eventqiu/user',
    unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
    adminPath: '/admin-eventqiu',
    tourPath: '/',
    notFound: '/not-found',
    locale: 'en',
    enableMock: false,
}

export default appConfig

